:root {
    --color1: #91cff9;
    --color2: #2980b9;
    --color3: #3498db;
    --color4: #333333;
    --color5: #f6f8fd;
    --color6: #2ecc71;
    --color7: #15659A;

    --colorError1: #ff0000;
    --colorError2: #fd9c3d;

    --audiowide: 'Audiowide', cursive;

    --wrapper-max-width: 1080px;
}
